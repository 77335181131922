export const jobCards = [
  {
    logo: './images/rea.png',
    title: 'REA Group',
    jobTitle: 'Senior Developer',
    from: '2022',
    to: 'present',
    description: [
      'Currently working as a Senior Devloper at REA Group within the Marketplace tribe. My journey here has only just started and I’m very excited to see where it will take me!',
    ],
  },
  {
    logo: './images/annalise.png',
    title: 'annalise.ai',
    jobTitle: 'Senior Software Engineer',
    from: '2021',
    to: '2022',
    description: [
      'Worked primarily as a front end developer on the annalise CXR & CTB products, a medical imaging AI tool used to assist clinicians with the interpretation of radiological imaging studies. Here I worked with:',
      '• Chest X-Ray & CT Brain products within the annalise application. These were both build using React & Electron',
      '• Backend skills using node.js & Postgres',
      '• Lead a feature for a log adaptor built in golang to communicate between the annalise product and Inteleviewer',
      '• Mentoring junior developers',
    ],
  },
  {
    logo: './images/digio.jpeg',
    title: 'DigIO',
    jobTitle: 'Software Engineer',
    from: '2018',
    to: '2020',
    description: [
      'Worked with various clients (namely ANZ and Reecetech) in a consultative manner as a front end engineer. During this time, my responsibilities and skill set included:',
      '• Expertise in React (and other front end technologies), the ability to make decisions around the technology and communicate this expertise',
      '• Developing back end skills in relational database design and implementation (DynamoDB), API calls and data implementation (Java and Python)',
      '• Mentoring interns and junior engineers',
      '• Strong communication skills and the ability to explain concepts to both the technical and business sides of an organisation',
      '• Extra-curricular involvement in DigIO across the swag team, involving budget management, design decisions, consulting the company wants and needs, and making branding and marketing decisions within and outside of the company',
    ],
  },
  {
    logo: './images/odecee.png',
    title: 'Odecee',
    jobTitle: 'Associate Software Engineer',
    from: '2016',
    to: '2018',
    description: [
      'Also a consulting company, clients were ANZ and CoreLogic. Throughout this time, I began as a front end engineer and dabbled in test automation. This included:',
      '• Producing automated test scripts in front end (Solenium, Webdriver, Protractor and Cucumber) and back end (Mocha)',
      '• Understanding and capturing edge cases in test automation design',
      '• Patching small bugs where possible',
    ],
  },
  {
    logo: './images/nab.png',
    title: 'nab',
    jobTitle: 'IBL Student',
    from: '2015',
    to: '2015',
    description: [
      'As part of Industry Based Learning, completed a 6-month internship with NAB around Cloud Engineering. This internship touched in:',
      '• Provisioning toolings in AWS',
      '• Building a shopfront site to gather requirements for teams building in the platform',
    ],
  },
  {
    logo: './images/monash.png',
    title: 'Monash University',
    jobTitle: 'Bachelor of Computer Science',
    from: '2013',
    to: '2015',
    description: [
      'Attended Monash University primarily on the Clayton campus. While my degree was in Computer Science, I also did units in philosophy and games development. For the final six months of my degree I completed an IBL placement at NAB which helped me get a foot in the door in the industry and to kick-start my career.',
    ],
  },
]
